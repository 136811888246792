import { useRouter } from 'next/router'

import type { ComponentAnalyticsClickProps } from '@cms/analytics/types'
import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { pushRoute } from '@knauf-group/ct-designs/utils/utils'

import Ribbon from './Ribbon'
import type { CMSRibbonWrapperProps, RibbonProps } from './Ribbon.types'

const RibbonWrapper: React.FC<CMSRibbonWrapperProps> = ({ fields }) => {
  const router = useRouter()
  const { normalizeReference } = useNormalizeReference()

  const analyticsProps: ComponentAnalyticsClickProps = {
    targetType: 'link',
    cmsComponent: 'content_ribbon',
  }
  const normalizedReference = normalizeReference({
    reference: fields?.primaryReference,
    analyticsProps,
  })

  const ribbonProps: RibbonProps = {
    dataCy: 'ribbon',
    // Ribbon will always have a reference, at least by content definition
    handleClick: () => pushRoute(normalizedReference, router),
    primaryRef: normalizedReference,
  }

  return <Ribbon {...ribbonProps} />
}

export default RibbonWrapper
