import Link from 'next/link'

import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import type { RibbonProps } from './Ribbon.types'

const Ribbon: React.FC<RibbonProps> = ({ handleClick, primaryRef, dataCy }) => {
  const theme = useTheme()

  const styles = {
    container: {
      backgroundColor: theme.palette.grey[100],
      p: 0,
      cursor: 'pointer',
      '&:hover svg': {
        '&:dir(rtl)': {
          transform: 'translate(8px, 0) rotate(180deg)',
        },
        '&:dir(ltr)': {
          transform: 'translate(8px, 0)',
        },
      },
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      my: '7px',
    },
  }

  return (
    <Container
      onClick={handleClick}
      maxWidth={false}
      disableGutters
      sx={styles.container}
      data-cy={dataCy}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={9} lg={8} mx="auto">
          <Box sx={styles.contentWrapper} data-cy={`${dataCy}-label-container`}>
            <WebLink
              prefetch={false}
              disableClick
              reference={primaryRef}
              nextLinkComponent={Link}
              color="primary"
              endIcon={<ArrowForward sx={RTLIconStyles} />}
              dataCy={`${dataCy}-entry-link-anchor`}
            >
              {primaryRef.label}
            </WebLink>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Ribbon
